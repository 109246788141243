import { type NextPage } from "next";
import Link from "next/link";

import { isDesktopAppMode } from "~/auth/interop/common";
import { withAppShell } from "~/components/withAppShell";

const Error404Page: NextPage = () => {
	const route = isDesktopAppMode() ? "/docs" : "https://www.moment.dev";

	return (
		<div className="flex items-center justify-center min-h-screen bg-primary">
			<div className="text-center text-4xl font-bold border-r border-primary pr-4">404</div>
			<div className="flex flex-col gap-1 pl-4 text-secondary text-base">
				<div className="text-center text-lg">Page not found</div>
				<div>
					<Link href={route}>← Back home</Link>
				</div>
			</div>
		</div>
	);
};

export default withAppShell(Error404Page);
