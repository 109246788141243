import { type NextPage } from "next";

import { isDesktopAppMode } from "~/auth/interop/common";
import { Redirect } from "~/components/navigation/Redirect";

import Error404Page from "./404.page";

const IndexPage: NextPage = () => {
	if (isDesktopAppMode()) {
		return <Redirect to="/docs" replace />;
	}
	return <Error404Page />;
};

export default IndexPage;
