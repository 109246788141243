import { useRouter } from "next/router";
import { type FC, useEffect } from "react";

export type RedirectProps = {
	to: string;
	replace?: boolean;
};

export const Redirect: FC<RedirectProps> = ({ to, replace }) => {
	const router = useRouter();

	useEffect(() => {
		if (replace) {
			void router.replace(to);
		} else {
			void router.push(to);
		}
	}, [replace, router, to]);

	return null;
};
